import { Button, Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState, useContext, useMemo, useEffect } from "react";
import axios from "axios";
import { useRouter } from "next/router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { GlobalContext } from "./context/Global";
import countryList from 'react-select-country-list'
import { toast,Bounce } from "react-toastify";
interface ContactFormProps {
  type?: string;
}
const customStyles = {
  control: (styles: any, { isFocused }: any) => ({
    ...styles,
    borderRadius: 0,
    backgroundColor: "white",
    borderStyle: isFocused ? "none" : "none",
    boxShadow: isFocused ? "none" : "none",
    borderBottom: isFocused ? "1px solid #080a0e" : "1px solid #080a0e",
    background: "transparent",
    color: "#8c8c8c",
    "&:hover": {
      borderBottom: "1px solid #080a0e",
    },
    "@media (max-width: 1360px)": {
      fontSize: "12px",
    },
  }),
  singleValue: (styles: any) => ({
    ...styles,
    width: "100%",
    background: "transparent",
    color: "#8c8c8c",
    fontSize: "17px",
    // paddingBottom: '0.71rem',
    "@media (max-width: 1360px)": {
      fontSize: "12px",
    },
    "@include media-breakpoint-up(xxl)": {
      fontSize: "1.0625rem",
    },
  }),
  placeholder: (styles: any, { isFocused }: any) => ({
    ...styles,
    width: "100%",
    background: "transparent",
    color: isFocused ? "#8c8c8c" : "#8c8c8c",
    fontSize: "17px",
    "@media (max-width: 1360px)": {
      fontSize: "12px",
    },
    "@include media-breakpoint-up(xxl)": {
      fontSize: "1.0625rem",
    },
    "@-moz-document url-prefix()": {
      color: "#c4b5b5" /* Adjust the color to match your design */,
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: "0px",
  }),
  option: (styles: any,state:any) => ({
    ...styles,
    width: "100%",
    marginBottom: '2px',
    background: state.isFocused || state.isSelected ? '#e9e9e9' : 'transparent',
    color: "#8c8c8c",
    fontSize: "17px",
    "@media (max-width: 1360px)": {
      fontSize: "12px",
    },
    "@include media-breakpoint-up(xxl)": {
      fontSize: "1.0625rem",
    },
  }),
};

const ContactForm = ({ type }: ContactFormProps) => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { usercountryCode, country } = useContext<any>(GlobalContext);
  const options = [
    { value: "Rs.5,00,000 - Rs.15,00,000", label: "₹5,00,000 - ₹15,00,000" },
    { value: "Rs.15,00,000 - Rs.25,00,000", label: " ₹15,00,000 - ₹25,00,000" },
    { value: "Above Rs.25,00,000", label: "Above ₹25,00,000" },
  ];

  const InquiryOption=[
    {value: '', label: 'Select',},
    {value:"General Inquiries",label:"General Inquiries"},
    {value:"New Customer Inquiry",label:"New Customer Inquiry"},
    {value:"Existing Customer Support",label:"Existing Customer Support"},
    {value:"Partnership Opportunities",label:"Partnership Opportunities"},
    {value:"Media & Press Release",label:"Media & Press Release"},
    {value:"Job Opportunities",label:"Job Opportunities"},
    {value:"Feedback & Suggestions",label:"Feedback & Suggestions"},
  ]

  const inputStyle: React.CSSProperties = {
    borderStyle: isFocused ? "none" : "none",
    boxShadow: isFocused ? "none" : "none",
    borderBottom: isFocused ? "1px solid #080a0e" : "1px solid #080a0e",
    borderRadius: 0,
    width: "100%",
  };
  const RegisterSchema = Yup.object().shape({
    FirstName: Yup.string().min(3, "First Name must be at least 3 characters")
    .max(100, "First Name can't be longer than 100 characters").required("Required"),
    LastName: Yup.string().nullable()
    .max(50, "Last Name can't be longer than 100 characters"),
      Email: Yup.string()
      .trim()
      .max(50, "Email can't be longer than 50 characters")
      .required("Required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Email has an invalid format")
      .test('no-spaces', 'Email should not contain spaces', (value?: string) => !/\s/.test(value || ''))
      .test('no-consecutive-dots', 'Email should not contain consecutive dots', (value?: string) => !/\.\./.test(value || ''))
      .test('no-trailing-dot', 'Email should not end with a dot', (value?: string) => {
        if (!value) return true; // Skip validation if value is empty
        const [localPart] = value.split('@');
        return !localPart.endsWith('.');
      })
      .test('valid-domain', 'Invalid domain format', (value?: string) => {
        if (!value) return true; // Skip validation if value is empty
        const domain = value.split('@')[1];
        if (!domain) return false;
        // Validate domain format with generalized pattern
        return /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(domain);
      })
      .test('no-leading-dot-in-domain', 'Domain should not start with a dot', (value?: string) => {
        if (!value) return true;
        const domain = value.split('@')[1];
        if (!domain) return false;
        return !domain.startsWith('.');
      })
      .test('valid-TLD', 'Top-level domain should be valid', (value?: string) => {
        if (!value) return true;
        const domain = value.split('@')[1];
        if (!domain) return false;
        const tld = domain.split('.').pop(); // Get the TLD (last part of the domain)
        return !!tld && tld.length >= 2 && tld.length <= 63;
      })
      .test('no-XSS-characters', 'Email contains invalid characters', (value?: string) => {
        const xssPattern = /[<>]/;
        return value ? !xssPattern.test(value) : true;
      })
      .test('no-SQL-injection', 'Email contains SQL injection pattern', (value?: string) => {
        if (!value) return true;
        // Allow `xn--` specifically in the domain part of the email
        const [localPart, domainPart] = value.split('@');
        const sqlInjectionPatternLocalPart = /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|OR|AND)\b|'|"|;|=|--)/i;
        const sqlInjectionPatternDomainPart = /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|OR|AND)\b|'|"|;|=)/i;
        return (
          !sqlInjectionPatternLocalPart.test(localPart) &&
          (!domainPart || !sqlInjectionPatternDomainPart.test(domainPart))
        );
      })
      .test('no-disposable-email', 'Disposable email addresses are not allowed', (value?: string) => {
        if (!value) return true;
        const domain = value.split('@')[1];
        const disposableDomains = [
          "tempmail.com", 
          "10minutemail.com", 
          "guerrillamail.com", 
          "mailinator.com", 
          "yopmail.com"
        ];
        return !disposableDomains.includes(domain);
      }),
    Message: Yup.string().min(3, "Message must be at least 3 characters")
    .max(1000, "Message can't be longer than 1000 characters").required("Required"),
  });

  const formik:any = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      Company: "",
      Message: "",
      Newsletter: false,
      phCountryCode: usercountryCode?usercountryCode:'in',
      countryName: country,
      Budget: "",
      SubmittedFrom: "contactus",
      Country:"",
      InquiryType:""
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values: any) => {
      setLoading(true);
      if (values?.phCountryCode) {
        delete values.phCountryCode;
      }

      if (values?.countryName) {
        delete values.countryName;
      }
      Object.keys(values).forEach((k) => {
        if (!values[k]) {
          values[k] = null;
        }
      });

      if (values?.Country?.label) {
        values.Country = values.Country.label;
      }

      const trimmedValues = {
        ...values,
        Email: values.Email.trim(),
      };

      await axios
      .post(
        `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/api/contact-uses`,
        { data: trimmedValues },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_TOKEN}`,
          },
        }
      )
      .then((res) => {
        axios.post('/api/create-contact',
          {
            Email: values.Email,
            FirstName: values.FirstName,
            LastName: values.LastName,
            Phone: values.Phone,
            Company: values.Company,
          },
        ).then((resPonse)=>{
          setLoading(false);
          console.log(resPonse)
          if (res?.data?.data?.id) {
            router.push(
              `/thank-you?submissionGuid=&submissionGuid=${res?.data?.data?.id}`
            );
          }
        }).catch((error)=>{
          setLoading(false);
          console.log(error);
          if (res?.data?.data?.id) {
            router.push(
              `/thank-you?submissionGuid=&submissionGuid=${res?.data?.data?.id}`
            );
          }
          // toast.error(error.message, {
          //   position: "bottom-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   transition: Bounce,
          //   });
        })
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
    },
  });
  const countryOptions = useMemo(() => {
    const data = countryList().getData();
    return [
      { value: '', label: 'Select', },
      ...data.map((country) => ({
        value: country.label,
        label: country.label,
      })),
    ];
  }, []);
  
  return (
    <div
    id={type}
      className={
        type === "contact" ? "contact-form home-contact" : "contact-form"
      }
    >
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={6} className="first-half">
            <p>
              <input
                type="text"
                name="FirstName"
                placeholder="First name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.FirstName}
              />
              {formik.errors.FirstName && (
                <div className="invalid-feedback d-block position-absolute">
                  {formik.errors.FirstName}
                </div>
              )}
            </p>
          </Col>
          <Col md={6} className="second-half">
            <p>
              <input
                type="text"
                name="LastName"
                placeholder="Last name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.LastName}
              />
              {formik.errors.LastName && (
                <div className="invalid-feedback d-block position-absolute">
                  {formik.errors.LastName}
                </div>
              )}
            </p>
          </Col>
          <Col md={6} className="first-half">
            <p>
              <input
                type="text"
                name="Email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Email}
              />
              {formik.errors.Email && (
                <div className="invalid-feedback d-block position-absolute">
                  {formik.errors.Email}
                </div>
              )}
            </p>
          </Col>
          <Col md={6} className="second-half mb-5">
            <>
            <div className={type === 'contact' ? "phInput" : 'contact-us-phn'}>
              <PhoneInput
                onFocus={() => setIsFocused(true)}
                inputStyle={inputStyle}
                value={formik.values.Phone}
                countryCodeEditable={false}
                country={formik?.values?.phCountryCode}
                onChange={(value: any, data: any) => {
                  formik.setFieldTouched("Phone", true);
                  formik.setFieldValue("phCountryCode", data.dialCode);
                  formik.setFieldValue("Phone", value);
                  formik.setFieldValue("phCountryCode", data?.dialCode);
                  formik.setFieldValue("countryName", data?.name);
                }}
              />
              {formik.errors?.Phone && formik.touched?.Phone && (
                <div className="invalid-feedback d-block position-absolute">
                  {formik.errors.Phone}
                </div>
              )}
            </div>
            </>
          </Col>
          <Col xs={12}>
            <p>
              <input
                type="text"
                name="Company"
                placeholder="Company name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Company}
              />
            </p>
          </Col>
          <Col xs={6} className="mb-5">
              <div>
                <Select
                  options={countryOptions}
                  name="Country"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("Country", selectedOptions?.value);
                  }}
                  styles={customStyles}
                  placeholder="Country"
                />
              </div>
            </Col>
          <Col xs={6} className="mb-5">
              <div>
                <Select
                  options={InquiryOption}
                  name="InquiryType"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("InquiryType", selectedOptions?.value);
                  }}
                  styles={customStyles}
                  placeholder="Inquiry Type"
                />
              </div>
            </Col>
            {(((formik?.values?.phCountryCode == "91"||formik?.values?.phCountryCode == "in") && formik?.values?.Country=="India")&&(formik.values.InquiryType === "New Customer Inquiry" || formik.values.InquiryType === "Existing Customer Support")) && (
            <Col xs={12} className="mb-5">
              <div>
                <Select
                  options={options}
                  name="Budget"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("Budget", selectedOptions?.value);
                  }}
                  styles={customStyles}
                  placeholder="Estimated Project Budget"
                />
              </div>
            </Col>
          )}
          <Col>
            <p>
              <textarea
                name="Message"
                placeholder="Your Message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Message}
              />
              {formik.errors.Message && (
                <div className="invalid-feedback d-block position-absolute">
                  {formik.errors.Message}
                </div>
              )}
            </p>
          </Col>
        </Row>
        <div className="fs-12 contact-form-check">
          <label>
            <input
              type="checkbox"
              onClick={(e: any) =>
                formik.setValues((prevValues:any) => ({
                  ...prevValues,
                  Newsletter: e.target.checked,
                }))
              }
            />{" "}
            Subscribe to Our Expertly Curated Newsletters.
          </label>
        </div>
        <Button disabled={loading} type="submit">{loading ? "Sending..." : "Send Message"}</Button>
      </Form>
    </div>
  );
};

export default ContactForm;
