export const levels = [
  {
    level: "l1",
    slug: "application-development",
  },
  {
    level: "l1",
    slug: "Product-engineering",
  },
  {
    level: "l1",
    slug: "staff-augmentation",
  },
  {
    level: "l1",
    slug: "Enterprise-solutions",
  },
  {
    level: "l1",
    slug: "data-analytics",
  },
  {
    level: "l1",
    slug: "machine-learning",
  },
  {
    level: "l1",
    slug: "artificial-intelligence",
  },
  {
    level: "l1",
    slug: "robotic-process-automation",
  },
  {
    level: "l1",
    slug: "cloud-services",
  },
  {
    level: "l1",
    slug: "devOps-as-a-service",
  },
  {
    level: "l1",
    slug: "cloud-maintenance",
  },
  {
    level: "l1",
    slug: "digital-transformation-consulting",
  },
  {
    level: "l1",
    slug: "product-consulting",
  },
  {
    level: "l1",
    slug: "devOps-consulting",
  },
  {
    level: "l1",
    slug: "artificial-intelligence-consulting",
  },
  {
    level: "l1",
    slug: "cloud-transformation-services",
  },
  {
    level: "l1",
    slug: "enterprise-solution-integration",
  },
  {
    level:"l1",
    slug:"intelligent-automation-solutions"
  },
  { level: "l2", slug: "Power-BI" },
  { level: "l2", slug: "BI-dashboard" },
  { level: "l2", slug: "automated-reporting" },
  { level: "l2", slug: "DevOps-management" },
  { level: "l2", slug: "DevOps-Automation" },
  { level: "l2", slug: "predictive-analysis" },
  { level: "l2", slug: "cognitive-search-services" },
  { level: "l2", slug: "natural-language-processing" },
  { level: "l2", slug: "continuous-integration-continuous-deployment" },
  {
    level: "l2",
    slug: "audio-image-and-video-artificial-intelligence",
  },
  { level: "l2", slug: "computer-vision" },
  { level: "l2", slug: "vision-analytics" },
  { level: "l2", slug: "RPA-process-optimization" },
  { level: "l2", slug: "hybrid-app-development" },
  { level: "l2", slug: "technical-expertise-provision" },
  { level: "l2", slug: "hospitality-management-systems" },
  { level: "l2", slug: "product-rescue" },
  // { level: "l2", slug: "outsourced-product-development" },
  { level: "l2", slug: "legacy-software-modernization" },
  { level: "l2", slug: "architecture-and-design" },
  { level: "l2", slug: "data-and-apps-migration" },
  { level: "l2", slug: "aws" },
  { level: "l2", slug: "azure" },
  { level: "l2", slug: "GCP" },
  { level: "l2", slug: "Docker-SWARM" },
  { level: "l2", slug: "pay-as-you-go-support" },
  { level: "l2", slug: "emergency-support" },
  { level: "l2", slug: "application-modernization" },
  { level: "l2", slug: "rpa-implementation" },
  { level: "l2", slug: "blockchain" },
  { level: "l2", slug: "product-requirements-definition" },
  { level: "l2", slug: "artificial-intelligence-solution-evaluation" },
  { level: "l2", slug: "data-analysis-and-modeling" },
  { level: "l2", slug: "cloud-adoption-and-migration" },
  {
    level: "l2",
    slug: "continuous-integration-and-continuous-delivery-pipeline",
  },
  { level: "l2", slug: "web-app-development" },
  { level: "l2", slug: "mobile-app-development" },
  { level: "l2", slug: "pwa-development" },
  { level: "l2", slug: "process-assessment-and-consultation" },
  { level: "l2", slug: "development-and-implementation" },
  { level: "l2", slug: "cloud-migration-and-integration" },
  { level: "l2", slug: "cloud-native-development" },
  { level: "l2", slug: "custom-product-engineering" },
  { level: "l2", slug: "dedicated-team-augmentation" },
  { level: "l2", slug: "devOps-assessment" },
  { level: "l2", slug: "agile-transformation" },
  { level: "l2", slug: "product-strategy" },
  { level: "l2", slug: "team-staff-augmentation" },
  { level: "l2", slug: "learning-management-systems" },
  { level: "l2", slug: "electronic-health-record-systems" },
  { level: "l2", slug: "enterprise-insight-and-intelligent-systems" },
  { level: "l2", slug: "data-analysis-as-a-service" },
  { level: "l2", slug: "data-visualisation" },
  { level: "l2", slug: "ML-model-development-and-training" },
  { level: "l2", slug: "conversational-artificial-intelligence" },
  { level: "l2", slug: "open-GPT" },
  { level: "l2", slug: "managed-support" },
  { level: "l2", slug: "Kubernetes" },
  { level: "l2", slug: "user-experience-design" },
  { level: "l2", slug: "strategy-and-planning" },
  { level: "l2", slug: "devops-assessment-and-strategy" },
  { level: "l2", slug: "laravel-development-service" },
  { level: "l2", slug: "oracle-apex-application-development" },
  { level: "l2", slug: "netsuite-development" },
  { level: "l2", slug: "microsoft-dynamics" },
  { level: "l2", slug: "salesforce-development" },
  { level: "l2", slug: "large-language-model" },
  { level: "l2", slug: "oracle-apex-application-development" },
  { level: "l2", slug: "netsuite-development" },
  { level: "l2", slug: "salesforce-development" },
  { level: "l2", slug: "microsoft-dynamics" },
  { level: "l2", slug: "cloud-integration-services" },
  { level: "l2", slug: "cloud-data-and-AI" },
  { level: "l2", slug: "generative-AI" },
  { level: "l2", slug: "deep-learning-development" },
  { level: "l2", slug: "MLOps-consulting-services" },
  {level:"l2",slug:"product-support-and-maintenance"},
  {level:"l2",slug:"data-visualization"},
  {level:"l2",slug:"data-warehousing-solutions"},
  {level:"l2",slug:"data-governance-solutions"},
  {level:"l2",slug:"QA-as-a-Service"},
  {level:"l2",slug:"workato-integration-solutions"},
  {level:"l2",slug:"boomi-integration-solution"},
  {level:"l2",slug:"mulesoft-integration-solutions"},
  {level:"l2",slug:"dynamics-365-solutions"},
  {level:"l2",slug:"robotic-process-automations"},
  {level:"l2",slug:"IoT-development-service"},
  {level:"l2",slug:"Enterprise-AI-Solution"},
  {level:"l2",slug:"intelligent-automation-services"}
];
